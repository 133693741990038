.KIWI-single-arrow {
  display: inline-block;
  height: 0;
  width: 0;
  margin-left: 12px;
  border-left: 5.5px solid rgba(0, 0, 0, 0);
  border-right: 5.5px solid rgba(0, 0, 0, 0);
  border-top: 5.5px solid rgba(0, 0, 0, 0.25);
  position: relative;
}

.KIWI-double-arrow {
  position: relative;
  margin: 0 12px;
}

.KIWI-double-arrow::before {
  content: '';
  display: inline-block;
  height: 0;
  width: 0;
  position: absolute;
  top: -8.5px;
  border-left: 5.5px solid rgba(0, 0, 0, 0);
  border-right: 5.5px solid rgba(0, 0, 0, 0);
  border-bottom: 5.5px solid rgba(0, 0, 0, 0.25);
}

.KIWI-double-arrow::after {
  content: '';
  display: inline-block;
  height: 0;
  width: 0;
  position: absolute;
  top: 1.5px;
  border-left: 5.5px solid rgba(0, 0, 0, 0);
  border-right: 5.5px solid rgba(0, 0, 0, 0);
  border-top: 5.5px solid rgba(0, 0, 0, 0.25);
}